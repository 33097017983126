import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subService'
})
export class SubServicePipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(items: any[], serviceTypeIDF: number): any {
    if(items != undefined) return items.filter(item => item.ServiceTypeIDF === serviceTypeIDF);
    //if(items != undefined && serviceTypeIDF != 0) return items.filter(item => item.ServiceTypeIDF === serviceTypeIDF);
  }

}
