import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule, NgbNavModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchwizardModule } from 'angular-archwizard';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SimplebarAngularModule } from 'simplebar-angular';

// Import the module from the SDK
//import { AuthModule } from '@auth0/auth0-angular';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
//import { GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';

//https://auth0.com/docs/quickstart/spa/angular/01-login
// Import the module from the SDK
// import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
// import { environment as env } from '../environments/environment';

//https://www.npmjs.com/package/@abacritt/angularx-social-login
// import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
// import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
// import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
// import { GoogleLoginProvider } from 'angularx-social-login';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { HowItWorksComponent } from './components/common/how-it-works/how-it-works.component';
import { HomeoneBlogComponent } from './components/pages/home-demo-one/homeone-blog/homeone-blog.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { HomeoneDestinationsComponent } from './components/pages/home-demo-one/homeone-destinations/homeone-destinations.component';
import { CategoryComponent } from './components/common/category/category.component';
import { HomeoneListingsComponent } from './components/pages/home-demo-one/homeone-listings/homeone-listings.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { HomeoneBannerComponent } from './components/pages/home-demo-one/homeone-banner/homeone-banner.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { HometwoBannerComponent } from './components/pages/home-demo-two/hometwo-banner/hometwo-banner.component';
import { HometwoListingsComponent } from './components/pages/home-demo-two/hometwo-listings/hometwo-listings.component';
import { HometwoDestinationsComponent } from './components/pages/home-demo-two/hometwo-destinations/hometwo-destinations.component';
import { HometwoEventsComponent } from './components/pages/home-demo-two/hometwo-events/hometwo-events.component';
import { HometwoBlogComponent } from './components/pages/home-demo-two/hometwo-blog/hometwo-blog.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { TeamComponent } from './components/common/team/team.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ProductsListComponent } from './components/pages/products-list/products-list.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { RelatedProductsComponent } from './components/pages/products-details/related-products/related-products.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { EventsComponent } from './components/pages/events/events.component';
import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
import { VerticalListingsRightSidebarComponent } from './components/pages/vertical-listings-right-sidebar/vertical-listings-right-sidebar.component';
import { VerticalListingsFullWidthComponent } from './components/pages/vertical-listings-full-width/vertical-listings-full-width.component';
import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
import { GridListingsRightSidebarComponent } from './components/pages/grid-listings-right-sidebar/grid-listings-right-sidebar.component';
import { GridListingsFullWidthComponent } from './components/pages/grid-listings-full-width/grid-listings-full-width.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { DashboardNavbarComponent } from './components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from './components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { CopyrightsComponent } from './components/pages/dashboard/copyrights/copyrights.component';
import { StatsComponent } from './components/pages/dashboard/stats/stats.component';
import { RecentActivitiesComponent } from './components/pages/dashboard/recent-activities/recent-activities.component';
import { DashboardMessagesComponent } from './components/pages/dashboard/dashboard-messages/dashboard-messages.component';
import { DashboardBookingsComponent } from './components/pages/dashboard/dashboard-bookings/dashboard-bookings.component';
import { DashboardWalletComponent } from './components/pages/dashboard/dashboard-wallet/dashboard-wallet.component';
import { DashboardReviewsComponent } from './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component';
import { DashboardInvoiceComponent } from './components/pages/dashboard/dashboard-invoice/dashboard-invoice.component';
import { DashboardMyProfileComponent } from './components/pages/dashboard/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardAddListingsComponent } from './components/pages/dashboard/dashboard-add-listings/dashboard-add-listings.component';
import { DashboardBookmarksComponent } from './components/pages/dashboard/dashboard-bookmarks/dashboard-bookmarks.component';
import { DashboardMyListingsComponent } from './components/pages/dashboard/dashboard-my-listings/dashboard-my-listings.component';
import { ServiceFormTemplateComponent } from './components/pages/service-form-template/service-form-template.component';
import { EseFamilyServicesComponent } from './components/pages/ese-family-services/ese-family-services.component';
import { RenewResidencyVisaComponent } from './components/pages/renew-residency-visa/renew-residency-visa.component';
import { CMSPageComponent } from './components/pages/cms-page/cms-page.component';
import { ProductsDetails2Component } from './components/pages/products-details2/products-details2.component';
import { UserDashboardComponent } from './components/pages/user-dashboard/user-dashboard.component';
import { TestComponent } from './components/pages/test/test.component';
import { UserProfileComponent } from './components/pages/user-profile/user-profile.component';
// import { ChatBotComponent } from './components/common/chat-bot/chat-bot.component';

import { LoginModule } from './Global/login/login.module';
import { PipesModule } from './Global/pipes/pipes.module';
import { environment } from 'src/environments/environment';
//import { LiveChatComponent } from './components/common/live-chat/live-chat.component';

var httpHeader = {
  headers: new HttpHeaders({
    'content-type': 'application/json'
  })
}

var httpHeaderMultipart = {
  headers: new HttpHeaders({
    'enctype': 'multipart/form-data',
    'Authorization': "" + localStorage.getItem('Token'),
    'UserType': "" + localStorage.getItem('UserType')
  })
}

//var apiUrl: string = 'https://esetadween-api.visioninformatics.in';

var AttachmentUrl: string = 'https://files.esetadween.com/';
var apiUrl: string = 'https://api.esetadween.com';

// Stage
// var AttachmentUrl: string = 'https://stage-cdn.esetadween.com/';
// var apiUrl: string = 'https://stage-api.esetadween.com';

// var apiUrl: string = 'https://localhost:44397';
// var AttachmentUrl: string = 'D:/0__Work/Angular Templates/API/uae-saas-api/';

var apiUrl: string;
var AttachmentUrl: string;

const href = window.location.href;
if (href.includes('http://localhost:4200/') || href.includes('http://localhost:53203/'))
{
  apiUrl = "https://localhost:44397";
  AttachmentUrl = "D:/0__Work/Angular Templates/Dev_2024/uae-saas-api/Assets/";
  // AttachmentUrl = "D:/0__Work/Angular Templates/API/uae-saas-api/Assets/";
}
else {
  apiUrl = 'https://v2-api.tadweenerp.site';
  AttachmentUrl = 'https://v2-file.tadweenerp.site/';
  // apiUrl = 'https://api.tadweenerp.site';
  // AttachmentUrl = 'https://files.tadweenerp.site/';
}

// const href = window.location.href;
// if (href.includes('https://stage.esetadween.com'))//STAGE URL
// {
//   AttachmentUrl = 'https://stage-cdn.esetadween.com/';
//   apiUrl = 'https://stage-api.esetadween.com';
// }
// else if (href.includes('https://esetadween.com'))//STAGE URL
// {
//   AttachmentUrl = 'https://files.esetadween.com/';
//   apiUrl = 'https://api.esetadween.com';
// }
// else {
//   AttachmentUrl = "https://localhost:44397";
//   apiUrl = "https://localhost:44397";
// }

@NgModule({
  declarations: [
    AppComponent,
    HomeDemoOneComponent,
    HomeDemoTwoComponent,
    AppDownloadComponent,
    HowItWorksComponent,
    HomeoneBlogComponent,
    FeedbackStyleOneComponent,
    HomeoneDestinationsComponent,
    CategoryComponent,
    HomeoneListingsComponent,
    FeaturesComponent,
    HomeoneBannerComponent,
    FooterStyleOneComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    HometwoBannerComponent,
    HometwoListingsComponent,
    HometwoDestinationsComponent,
    HometwoEventsComponent,
    HometwoBlogComponent,
    ComingSoonComponent,
    NotFoundComponent,
    AboutUsComponent,
    PartnerComponent,
    TeamComponent,
    FunfactsComponent,
    HowItWorksPageComponent,
    PricingComponent,
    GalleryComponent,
    FaqComponent,
    ContactComponent,
    FooterStyleTwoComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    ProductsListComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    RelatedProductsComponent,
    AuthorProfileComponent,
    CategoriesComponent,
    TopPlaceComponent,
    ListingsDetailsComponent,
    EventsDetailsComponent,
    EventsComponent,
    VerticalListingsLeftSidebarComponent,
    VerticalListingsRightSidebarComponent,
    VerticalListingsFullWidthComponent,
    GridListingsLeftSidebarComponent,
    GridListingsRightSidebarComponent,
    GridListingsFullWidthComponent,
    DashboardComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    CopyrightsComponent,
    StatsComponent,
    RecentActivitiesComponent,
    DashboardMessagesComponent,
    DashboardBookingsComponent,
    DashboardWalletComponent,
    DashboardReviewsComponent,
    DashboardInvoiceComponent,
    DashboardMyProfileComponent,
    DashboardAddListingsComponent,
    DashboardBookmarksComponent,
    DashboardMyListingsComponent,
    ServiceFormTemplateComponent,
    EseFamilyServicesComponent,
    RenewResidencyVisaComponent,
    CMSPageComponent,
    ProductsDetails2Component,
    UserDashboardComponent,
    TestComponent,
    UserProfileComponent,
    //ChatBotComponent,
    //LiveChatComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    SelectDropDownModule,
    NgxTypedJsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgSelectModule,
    NgbModule,
    NgbNavModule,
    NgbDropdownModule,
    ArchwizardModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SimplebarAngularModule,
    LoginModule,
    PipesModule,
    SocialLoginModule,

    //AuthModule.forRoot(environment.auth0)

    // AuthModule.forRoot({
    //   domain: 'dev-8law55djka4lr36q.us.auth0.com',
    //   clientId: 'br4TlHQ3OOOVs58pVLw4Fd7AjURGbxr7',
    //     authorizationParams: {
    //       redirect_uri: window.location.origin
    //     }
    // })
    // Import the module into the application, with configuration
    // AuthModule.forRoot({
    //   domain: 'dev-8law55djka4lr36q.us.auth0.com',
    //   clientId: '9cHwaHpz75mH1VOFl9bMi7p4gZEMVm1M',
    //   authorizationParams: {
    //     redirect_uri: window.location.origin
    //   }
    // }),

    // Import the module into the application, with configuration
    // AuthModule.forRoot({
    //   domain: env.auth.domain,
    //   clientId: env.auth.clientId,
    //   authorizationParams: {
    //     redirect_uri: env.auth.redirectUri,
    //     //audience: env.auth0.audience
    //   },
    //   //cacheLocation: 'localstorage'
    // }),
    // AuthModule.forRoot({
    //   ...env.auth,
    // }),
    // AuthModule.forRoot({
    //   domain: 'dev-8law55djka4lr36q.us.auth0.com',
    //   clientId: '9cHwaHpz75mH1VOFl9bMi7p4gZEMVm1M',
    //   authorizationParams: {
    //     //redirect_uri: "http://localhost:4200/#/ese-family-services"
    //     redirect_uri: window.location.origin
    //   }
    // }),
  ],
  providers: [DatePipe,
    { provide: 'httpHeader', useValue: httpHeader },
    { provide: 'httpHeaderMultipart', useValue: httpHeaderMultipart },
    { provide: 'APIURL', useValue: apiUrl },
    { provide: 'AttachmentURL', useValue: AttachmentUrl },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('888905099309-mdqrm987d4008jq1u46spr1lhc8upff0.apps.googleusercontent.com'),
          },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('243440595002882'),
          //   //provider: new FacebookLoginProvider('789836356189437'),
          //   //provider: new FacebookLoginProvider('169260719436271'),
          // },
          // {
          //     id: AmazonLoginProvider.PROVIDER_ID,
          //     provider: new AmazonLoginProvider('amzn1.application-oa2-client.f074ae67c0a146b6902cc0c4a3297935'),
          // },
          // {
          //     id: VKLoginProvider.PROVIDER_ID,
          //     provider: new VKLoginProvider('7624815'),
          // },
          // {
          //     id: MicrosoftLoginProvider.PROVIDER_ID,
          //     provider: new MicrosoftLoginProvider('0611ccc3-9521-45b6-b432-039852002705'),
          // },
          // {
          //     id: LinkedInLoginProvider.PROVIDER_ID,
          //     provider: new LinkedInLoginProvider("LinkedIn-client-Id", false, 'en_US')
          // },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthHttpInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         //provider: new GoogleLoginProvider('711285896249-m41n9o6qf4epogev5evjfgnqqkc0re95.apps.googleusercontent.com'),
    //         // provider: new GoogleLoginProvider('60444110770-radod3n2grkpvhb0e0u9e56p28d84m9v.apps.googleusercontent.com', { oneTapEnabled: false }),
    //         provider: new GoogleLoginProvider('711285896249-1m3s6sdbalf14qkdcogtua77t900vn5o.apps.googleusercontent.com'),
    //       },
    //     ],
    //   } as SocialAuthServiceConfig,
    // },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
