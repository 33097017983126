import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SubPageMenuPipe } from './sub-page-menu.pipe';
import { SubServicePipe } from './sub-service.pipe';

@NgModule({
  declarations: [
    SubPageMenuPipe,
    SubServicePipe
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  providers: []
  ,exports: [SubPageMenuPipe, SubServicePipe]
  ,schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PipesModule { }