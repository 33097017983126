<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="add-listings-box">
        <h3>Basic Informations</h3>
    
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-briefcase-alt'></i> Listing Title:</label>
                    <input type="text" class="form-control" placeholder="Name of your business">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Type / Category:</label>
                    <select>
                        <option>Select Category</option>
                        <option>Shops</option>
                        <option>Hotels</option>
                        <option>Restaurants</option>
                        <option>Fitness</option>
                        <option>Events</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxs-key'></i> Keywords:</label>
                    <input type="text" class="form-control" placeholder="Maximum 15 , should be separated by commas">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Location</h3>
    
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> City:</label>
                    <select>
                        <option>Select City</option>
                        <option>New York</option>
                        <option>London</option>
                        <option>Paris</option>
                        <option>Moscow</option>
                        <option>Rome</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> Address:</label>
                    <input type="text" class="form-control" placeholder="e.g. 55 County Laois">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> State:</label>
                    <input type="text" class="form-control">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> Zip-Code:</label>
                    <input type="text" class="form-control">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Gallery</h3>
    
        <div class="file-upload-box">
            <form action="/file-upload" class="dropzone"></form>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Details</h3>
    
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-text'></i> Description:</label>
                    <textarea cols="30" rows="7" class="form-control" placeholder="Details..."></textarea>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-envelope'></i> Email Address: <span>(optional)</span></label>
                    <input type="email" class="form-control">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-globe'></i> Website: <span>(optional)</span></label>
                    <input type="text" class="form-control">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-phone-call'></i> Phone: <span>(optional)</span></label>
                    <input type="text" class="form-control">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-facebook-square'></i> Facebook: <span>(optional)</span></label>
                    <input type="text" class="form-control" placeholder="https://www.facebook.com/">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-twitter'></i> Twitter: <span>(optional)</span></label>
                    <input type="text" class="form-control" placeholder="https://www.twitter.com/">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-linkedin'></i> Linkedin: <span>(optional)</span></label>
                    <input type="text" class="form-control" placeholder="https://www.linkedin.com/">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Facilities</h3>
    
        <div class="form-group">
            <ul class="facilities-list">
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="free-wifi" checked>
                        <span>Free WiFi</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="parking">
                        <span>Parking</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="fitness-center" checked>
                        <span>Fitness Center</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="non-smoking-rooms">
                        <span>Non-smoking Rooms</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="airport-shuttle" checked>
                        <span>Airport Shuttle</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="air-conditioning">
                        <span>Air Conditioning</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="events">
                        <span>Events</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="friendly-workspace">
                        <span>Friendly Workspace</span>
                    </label>
                </li>
            </ul>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Sidebar Widgets</h3>
    
        <div class="form-group">
            <div class="sidebar-widgets">
                <div class="box">
                    <span class="title">Booking Form</span>
                    <label class="switch">
                        <input type="checkbox">
                        <span></span>
                    </label>
                </div>

                <div class="box">
                    <span class="title">Price Range</span>
                    <label class="switch">
                        <input type="checkbox" checked>
                        <span></span>
                    </label>
                </div>

                <div class="box">
                    <span class="title">Instagram</span>
                    <label class="switch">
                        <input type="checkbox">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Opening Hours</h3>

        <div class="row opening-day align-items-center">
            <div class="col-lg-2 col-md-12">
                <h5>Monday</h5>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Opening Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Closing Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>
        </div>

        <div class="row opening-day align-items-center">
            <div class="col-lg-2 col-md-12">
                <h5>Tuesday</h5>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Opening Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Closing Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>
        </div>

        <div class="row opening-day align-items-center">
            <div class="col-lg-2 col-md-12">
                <h5>Wednesday</h5>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Opening Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Closing Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>
        </div>

        <div class="row opening-day align-items-center">
            <div class="col-lg-2 col-md-12">
                <h5>Thursday</h5>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Opening Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Closing Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>
        </div>

        <div class="row opening-day align-items-center">
            <div class="col-lg-2 col-md-12">
                <h5>Friday</h5>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Opening Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Closing Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>
        </div>

        <div class="row opening-day align-items-center">
            <div class="col-lg-2 col-md-12">
                <h5>Saturday</h5>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Opening Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Closing Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>
        </div>

        <div class="row opening-day align-items-center">
            <div class="col-lg-2 col-md-12">
                <h5>Sunday</h5>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Opening Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>

            <div class="col-lg-5 col-md-6">
                <select>
                    <option>Closing Time</option>
                    <option>Closed</option>
                    <option>1 AM</option>
                    <option>2 AM</option>
                    <option>3 AM</option>
                    <option>4 AM</option>
                    <option>5 AM</option>
                    <option>6 AM</option>
                    <option>7 AM</option>
                    <option>8 AM</option>
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>	
                    <option>1 PM</option>
                    <option>2 PM</option>
                    <option>3 PM</option>
                    <option>4 PM</option>
                    <option>5 PM</option>
                    <option>6 PM</option>
                    <option>7 PM</option>
                    <option>8 PM</option>
                    <option>9 PM</option>
                    <option>10 PM</option>
                    <option>11 PM</option>
                    <option>12 PM</option>
                </select>
            </div>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Pricing</h3>

        <div class="form-group">
            <label><i class='bx bx-purchase-tag'></i> Pricing:</label>
            <input type="text" class="form-control" placeholder="$542.00">
        </div>
    </div>
            
    <div class="add-listings-btn">
        <button type="submit">Submit Listings</button>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->