import { Component, Injector, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
// import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

  apiUrl:string;
  attachmentURL:string;
  userID: string = "";
  userName: string = "";
  photoURL: string = "";
  menuList: any;
  menuDetails: string = '';
  serviceList: any;
  isRTL: boolean = false;
  languageSetting: string = "0";
  isOpenLoginView:number=0;
  //languageID: number = 1;

  // constructor(private http: HttpClient, private injector: Injector, private router: Router, public auth: AuthService) {
    constructor(private http: HttpClient, private injector: Injector, private router: Router, public sanitizer: DomSanitizer) {
    this.apiUrl = this.injector.get('APIURL');
    this.attachmentURL = this.injector.get('AttachmentURL');
    if(localStorage.getItem('UserID') != null){
      this.userID = localStorage.getItem('UserID');
      this.userName = localStorage.getItem('UserName');
      this.photoURL = (localStorage.getItem('Photo').toString() == "") ? "assets/img/users/user-dummy-img.jpg" : this.attachmentURL + "Client/" + this.userID + "/" + localStorage.getItem('Photo');
    }
    if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
    // if(localStorage.getItem("IsRTL") == 'rtl') this.languageID = 1;
    // else this.languageID = 0;
  }

  ngOnInit(): void {
    // if(localStorage.getItem('IsRTL') != null && localStorage.getItem('IsRTL') == 'rtl'){
    //   this.isRTL = true;
    //   this.languageSetting = "2";
    // }
    // else{
    //   this.isRTL = false;
    //   this.languageSetting = "1";
    // }
    // this.actionGetAllService();
    this.actionGetAll_ActiveMenu();
  }

  // loginWithRedirect(): void {
  //   this.auth.loginWithRedirect();
  // }

  //= = = = = = = = = = = = = = = = = = = = = = = = =
  public getSantizeUrl(url : string) {
    if(url != undefined && url.indexOf('blob:') >= 0) return this.sanitizer.bypassSecurityTrustUrl(url);
    else return url;
  }

  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionGetAll_ActiveMenu(){
    var formData = new FormData();
    formData.append('IsQuickLink', "-1");
    formData.append('LanguageID', this.languageSetting);
    formData.append('FranchiseIDF', "24");

    this.http.post(this.apiUrl + "/PageMenu/Get_ActiveMenu", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data) => {
        this.menuList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetAllService

  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionGetAllService(){
    var formData = new FormData();
    formData.append('LanguageID', this.languageSetting);

    this.http.post(this.apiUrl + "/Service/DDL", formData, this.injector.get('httpHeaderMultipart')).subscribe(
    //this.http.get(this.apiUrl + "/Service/DDL").subscribe(
      (data) => {
        this.serviceList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetAllService

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionGetMenuDetails(id: number){
    var formData = new FormData();
    formData.append('PageMenuID', id.toString());
    formData.append('LanguageID', this.languageSetting);
    formData.append('FranchiseIDF', "24");

    this.http.post(this.apiUrl + "/PageMenu/Get_ActiveMenu_Details", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data) => {
        this.menuDetails = data[0].A3;
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetMenuDetails

  openModal(){
	  this.isOpenLoginView = Math.floor(Math.random() * 50);
  }//openModal()

  actionLogout(){
    localStorage.clear();
    localStorage.setItem('LanguageID', '0');
    this.router.navigate(['/']);
  }

  // -----------------------------------------------------------------------------------------------------
  getShortName(fullName: string) {
    var temp = '';
    temp = fullName.replace(/\s+/g, '-').toLowerCase().replace(/[()]/g,'');
    return temp;
  }//getShortName

  // -----------------------------------------------------------------------------------------------------
  changeLanguageSetting(e: any){
    if(e == 1)
    {
      this.languageSetting = "1";
      this.isRTL = true;
      localStorage.setItem('IsRTL', 'rtl');
      localStorage.setItem('LanguageID', '1');
      this.actionGetAll_ActiveMenu();
      window.location.reload();
    }
    else{
      this.languageSetting = "0";
      this.isRTL = false;
      localStorage.setItem('IsRTL', 'ltr');
      localStorage.setItem('LanguageID', '0');
      this.actionGetAll_ActiveMenu();
      window.location.reload();
    }
  }//changeLanguageSetting

  // actionIsRTL(isRTL: any){
  //   localStorage.clear();
  //   if(isRTL == true)
  //   {
  //     this.isRTL = true;
  //     localStorage.setItem('IsRTL', 'rtl');
  //     window.location.reload();
  //   }
  //   else{
  //     this.isRTL = false;
  //     localStorage.setItem('IsRTL', 'ltr');
  //     window.location.reload();
  //   }
  // }//actionIsRTL

}
