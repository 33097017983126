import { Component, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';
//import { TranslateService } from '@ngx-translate/core';
// import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CMSPageComponent implements OnInit {

  getScreenHeight: string;
  isProcessLoading: boolean = false;
  apiUrl:string;
  attachmentURL:string;
  httpHeaderMultipart: any;
  isRTL: boolean = false;
  userID: string = "";
  userName: string = "";
  photoURL: string = "";
  languageSetting: string = "0";
  menuPath: any;
  menuID: number = 0;
  isServicePage: boolean = false;
  //languageID: number = 1;
  menuList: any;
  menuDetails: any;
  serviceTypeList: any;
  serviceList: any;
  applyHere: string = "Apply Here";
  isOpenLoginView:number=0;
  loginFormSubmitted = false;
  isProcessLogin : boolean = false;
  registrationFormSubmitted = false;
  isProcessRegister : boolean = false;

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // constructor(private http: HttpClient, public translate: TranslateService, private injector: Injector, private route: ActivatedRoute, public sanitizer: DomSanitizer, public auth: AuthService) {
  constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient, private injector: Injector, private route: ActivatedRoute, private toastr: ToastrService, public sanitizer: DomSanitizer, private router: Router) {
    this.apiUrl = this.injector.get('APIURL');
    this.attachmentURL = this.injector.get('AttachmentURL');
    this.httpHeaderMultipart = this.injector.get('httpHeaderMultipart');
    if(this.languageSetting == "0") this.applyHere = "Apply Here";
    else this.applyHere = "للتقديم";

    this.route.queryParams.subscribe(params => {
      if(params['userName'] != undefined){
        localStorage.setItem('UserName', params['userName']);
        localStorage.setItem('UserID', params['userID']);
        localStorage.setItem('Token', params['token']);
        localStorage.setItem('EmailID', params['emailID']);
        localStorage.setItem('MobileNo', params['mobileNo']);
        localStorage.setItem('Photo', params['photo']);
      }
    });

    if(localStorage.getItem('UserID') != null){
      this.userID = localStorage.getItem('UserID');
      this.userName = localStorage.getItem('UserName');
      this.photoURL = (localStorage.getItem('Photo').toString() == "") ? "assets/img/users/user-dummy-img.jpg" : this.attachmentURL + "Client/" + this.userID + "/" + localStorage.getItem('Photo');
    }
    if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
    //console.log(this.userID);
    
    // if(localStorage.getItem("IsRTL") == 'rtl') this.languageID = 2;
    // else this.languageID = 1;
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  ngOnInit(): void {
    this.getScreenHeight = ((window.innerHeight / 2) / 2) + "px";
    this.route.queryParams.subscribe(
      params => {
        if(params['mid']){
          this.menuID =  params['mid'];
        }
      }
    );
    if(this.menuID == 0 && this.route.snapshot.params.mid != null){
      this.menuID = this.route.snapshot.params.mid;
    } else {
      this.menuID = 8;
    }
    this.menuList = [];
    this.menuDetails = [];
    this.actionGetAll_ActiveMenu();
    this.actionGetMenuDetails(this.menuID);
    if(this.menuID == 59){
      this.isServicePage = true;
      this.actionGetAllService();
    }

    fromEvent(window,'build').subscribe(e => {
      if(e["detail"] == "action_Get_Menu_Details"){
        this.actionGetMenuDetails(59);
      }
    });
    //setTimeout(() => { var c1 = <HTMLInputElement>document.querySelector("[autofocus]"); if (c1 != null) c1.focus(); }, 300);
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  frmCtrlRegistartion = new FormGroup({
    FirstName : new FormControl('',[Validators.required]),
    MiddleName : new FormControl('',[Validators.required]),
    LastName : new FormControl('',[Validators.required]),
    MobileNo : new FormControl('',[Validators.required]),
    EmailID : new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    Password : new FormControl('',[Validators.required, Validators.minLength(6)]),
    ConfirmPassword : new FormControl('',[Validators.required]),
  });

  get f() {
    return this.frmCtrlRegistartion.controls;
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  frmCtrlLogin = new FormGroup({
    UserName : new FormControl('',[Validators.required]),
    LgnPassword : new FormControl('',[Validators.required]),
  });

  get flgn() {
    return this.frmCtrlLogin.controls;
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  openModal(){
	  this.isOpenLoginView = Math.floor(Math.random() * 50);
  }//openModal()

  actionLogout(){
    localStorage.clear();
    localStorage.setItem('LanguageID', '0');
    this.router.navigate(['/']);
  }

  //= = = = = = = = = = = = = = = = = = = = = = = = =
  public getSantizeUrl(url : string) {
    if(url != undefined && url != "" && url.indexOf('blob:') >= 0) return this.sanitizer.bypassSecurityTrustUrl(url);
    else return url;
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  changeLanguageSetting(e: any){
    if(e == 1)
    {
      this.languageSetting = "1";
      this.isRTL = true;
      localStorage.setItem('IsRTL', 'rtl');
      localStorage.setItem('LanguageID', '1');
      this.actionGetAll_ActiveMenu();
      this.actionGetMenuDetails(this.menuID);
      window.location.reload();
      
      //document.querySelector('html').setAttribute('lang', 'ar');

      // const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
      // //htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
      // htmlTag.lang = "ar";

    }
    else{
      this.languageSetting = "0";
      this.isRTL = false;
      localStorage.setItem('IsRTL', 'ltr');
      localStorage.setItem('LanguageID', '0');
      this.actionGetAll_ActiveMenu();
      this.actionGetMenuDetails(this.menuID);
      window.location.reload();
    }
  }//changeLanguageSetting

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  public getSantizeHTML() {
    return this.sanitizer.bypassSecurityTrustHtml(this.menuDetails);
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionGetAll_ActiveMenu(){
    this.isProcessLoading = true;
    var formData = new FormData();
    formData.append('IsQuickLink', "-1");
    formData.append('LanguageID', this.languageSetting);
    formData.append('FranchiseIDF', "24");

    this.http.post(this.apiUrl + "/PageMenu/Get_ActiveMenu", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data) => {
        this.menuList = data;
        this.isProcessLoading = false;
      },
      (error) => {
        console.log(error);
        this.isProcessLoading = false;
      }
    );
  }//actionGetAll_ActiveMenu

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionGetMenuDetails(id){
    this.isServicePage = false;
    this.isProcessLoading = true;
    var formData = new FormData();
    formData.append('PageMenuID', id.toString());
    formData.append('LanguageID', this.languageSetting);
    formData.append('FranchiseIDF', "24");

    this.http.post(this.apiUrl + "/PageMenu/Get_ActiveMenu_Details", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data) => {
        this.menuDetails = data[0].A3;
        if(id == 59){
          this.isServicePage = true;
          this.actionGetAllService();
        }
        this.isProcessLoading = false;
      },
      (error) => {
        console.log(error);
        this.isProcessLoading = false;
      }
    );
  }//actionGetMenuDetails

  
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionGetAllService(){
    var formData = new FormData();
    formData.append('PageMenuID', "35");
    formData.append('LanguageID', this.languageSetting);

    this.http.post(this.apiUrl + "/Service/DDL_By_ServiceType", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data) => {
        this.serviceTypeList = data["TblServiceType"];
        this.serviceList = data["TblService"];
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetAllService()

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionRegistration(){
    this.registrationFormSubmitted = true;
    if (this.frmCtrlRegistartion.invalid) {
      return;
    }
    this.isProcessRegister = true;
    
    var formData = new FormData();
    formData.append('ServiceIdp', "0");
    formData.append('FirstName', (this.f.FirstName.value == null) ? '' : this.f.FirstName.value);
    formData.append('MiddleName', (this.f.MiddleName.value == null) ? '' : this.f.MiddleName.value);
    formData.append('LastName', (this.f.LastName.value == null) ? '' : this.f.LastName.value);
    formData.append('MobileNo', (this.f.MobileNo.value == null) ? '' : this.f.MobileNo.value);
    formData.append('EmailID', (this.f.EmailID.value == null) ? '' :  this.f.EmailID.value);
    formData.append('Password', (this.f.Password.value == null) ? '' :  this.f.Password.value);

    this.http.post(this.apiUrl + '/Client/Registration', formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data => {
        const res : any = data;
        if(res.status == "Done"){
          document.getElementById("closeModalButton").click();
          this.toastr.success(res.msg, res.status);
          this.frmCtrlRegistartion.reset();
        }else{
          this.toastr.error(res.msg, res.status);
        }
        this.isProcessRegister = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessRegister = false;
      })
    )
  }//actionSaveService

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionLogin(){
    this.loginFormSubmitted = true;
    if (this.frmCtrlLogin.invalid) {
      return;
    }
    this.isProcessLogin = true;
    
    var formData = new FormData();
    formData.append('EmailID', this.flgn.UserName.value);
    formData.append('Password', this.flgn.LgnPassword.value);

    this.http.post(this.apiUrl + '/Client/Login', formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data => {
        const res : any = data[0];
        if(res.msg != "Invalid credential"){
          document.getElementById("closeModalButton").click();
          this.toastr.success("Login Successfully.", "Done");
          this.frmCtrlLogin.reset();
          localStorage.setItem('UserName', res.FullName);
          localStorage.setItem('UserID', res.ClientIDP);
          localStorage.setItem('Token', res.CurrentToken);
          localStorage.setItem('EmailID', res.EmailID);
          localStorage.setItem('MobileNo', res.MobileNo);
          if(res.isRTL == 0) localStorage.setItem('IsRTL', "false");
          else localStorage.setItem('IsRTL', "false");
          window.location.reload();
        }else{
          this.toastr.error(res.msg, "Danger");
        }
        this.isProcessLogin = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessLogin = false;
      })
    )
  }//actionLogin

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
}
