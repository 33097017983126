<!-- Start Navbar Area -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->
<div class="navbar-area navbar-style-two">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo-light.png" height="60px" width="120px" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo-light.png" height="60px" width="120px" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What are you looking for?">
                    </form>

                    <ul class="navbar-nav">

                        <li class="nav-item" *ngFor="let menus of menuList | subPageMenu:0">
                            <a routerLink="/cms/{{menus.A1}}/{{menus.ID}}" (click)="actionGetMenuDetails(menus.ID)" routerLinkActive="router-link-active" class="nav-link">{{menus.A2}}</a>
                            <ul class="dropdown-menu" *ngFor="let subMenus of menuList | subPageMenu:menus.ID">
                                <li class="nav-item" *ngFor="let subMenus of menuList | subPageMenu:menus.ID"><a routerLink="/cms/{{subMenus.A1}}/{{subMenus.ID}}" (click)="actionGetMenuDetails(subMenus.ID)" routerLinkActive="router-link-active" class="nav-link">{{subMenus.A2}}</a></li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item" *ngFor="let menus of menuList"><a routerLink="/" (click)="actionGetMenuDetails(menus.ID)" class="nav-link">{{menus.A2}}</a></li> -->
                        <!-- <li class="nav-item" *ngFor="let menus of menuList">
                            <a *ngIf="menus.PageMenuIDF == 0" routerLink="/cms/{{menus.A1}}/{{menus.ID}}" (click)="actionGetMenuDetails(menus.ID)" routerLinkActive="router-link-active" class="nav-link">{{menus.A2}}</a>
                            <ul *ngIf="menus.PageMenuIDF != 0" class="dropdown-menu">
                                <li class="nav-item" *ngFor="let subMenus of menuList | subPageMenu:menus.PageMenuIDF"><a routerLink="/cms/{{subMenus.A1}}/{{subMenus.ID}}" (click)="actionGetMenuDetails(subMenus.ID)" class="nav-link">{{subMenus.A2}}</a></li>
                            </ul>
                        </li> -->
                        
                        <li class="nav-item">
                            <ng-select class="nav-link mt-3" [(ngModel)]="languageSetting" [multiple]="false" [clearable]="false" (change)="changeLanguageSetting($event)">
                                <ng-option value="0">English</ng-option>
                                <ng-option value="1">Arabic</ng-option>
                            </ng-select>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div *ngIf="userID != ''" class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                <span class="d-flex align-items-center">
                                    <!-- <img class="rounded-circle header-profile-user" src="assets/img/users/user-dummy-img.jpg" style="height: 30px; width: 30px;" alt="Header Avatar"> -->
                                    <img class="rounded-circle header-profile-user" [src]="getSantizeUrl(photoURL)" style="height: 30px; width: 30px;" alt="Header Avatar">
                                    <span class="text-start ms-xl-2"></span>
                                </span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                <h6 class="dropdown-header"><b>Welcome {{userName}}</b></h6>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" [routerLink]="['/user-dashboard']"><i class="bx bx-home align-middle"></i> <span class="align-middle"> Dashboard</span></a>
                                <a class="dropdown-item" [routerLink]="['/user-profile']"><i class="bx bx-cog align-middle"></i> <span class="align-middle"> Setting</span></a>
                                <a class="dropdown-item" href="" (click)="actionLogout()"><i class="bx bx-exit align-middle"></i> <span class="align-middle"> Logout</span></a>
                            </div>
                        </div>
                        <div class="option-item">
                            <button *ngIf="userID == ''" type="button" class="btn btn-default" (click)="openModal()"><span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span></button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="navbar-search-box">
                                <label><i class="flaticon-search"></i></label>
                                <input type="text" class="input-search" placeholder="What are you looking for?">
                            </form>
                        </div>

                        <div class="option-item">
                            <button type="button" class="btn btn-default" (click)="openModal()"><span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->


<app-login [IsOpenLoginView]=isOpenLoginView></app-login>

<div *ngIf="isProcessLoading == false && menuList != null && menuDetails != null && menuList.length != 0 && menuDetails.length != 0" style="margin-top: 80px;" [innerHTML]="getSantizeHTML()"></div>
<div style="margin-top: 200px; margin-bottom: 200px;" *ngIf="isProcessLoading == false && menuList != null && menuDetails != null && menuList.length == 0 && menuDetails.length == 0"><h1 class="text-center"> -- No Data Found -- </h1></div>
<div style="margin-top: 200px; margin-bottom: 200px;" *ngIf="isProcessLoading == true" class="text-center"><i class="fa-refresh fa-spin fa ml-2" style="font-size: 40px;"></i></div>

<div *ngIf="isServicePage" class="row" style="margin-top: 120px;">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-center">
        <h1><strong>Services</strong></h1>
        <!-- <h1><strong>{{serviceTypeName}}</strong></h1> -->
        <hr>
        <div class="accordion pb-5" id="serviceTypeToService">
            <!-- <div *ngFor="let serviceTypes of serviceTypeList; let i= index;"> -->
                    <div class="accordion-item" *ngFor="let serviceTypes of serviceTypeList; let i= index;">
                        <h2 class="accordion-header" id="heading{{i}}">
                            <a class="accordion-button" type="button" data-bs-toggle="collapse" href="#collapse{{i}}" aria-expanded="true" aria-controls="collapse{{i}}">{{serviceTypes.A1}}</a>
                            <!-- <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse{{i}}" aria-expanded="true" aria-controls="collapse{{i}}">
                                {{serviceTypes.A1}}
                            </button> -->
                        </h2>
                        <div id="collapse{{i}}" class="accordion-collapse collapse" [ngClass]="{ 'show': i == 0 }" data-bs-parent="#serviceTypeToService">
                            <div class="accordion-body">
                                <div class="live-preview serviceList">
                                    <table class="table table-striped table-hover mt-2 mb-2">
                                        <tbody>
                                            <tr *ngFor="let service of serviceList | subService:serviceTypes.ID">
                                                <td class="col-6 text-start"><strong>{{service.A1}}</strong></td>
                                                <td class="col-3">
                                                    <div class="arrow"> ➜ </div>
                                                </td>
                                                <td class="col-3">
                                                    <button *ngIf="userID != '' && !(service.ServiceTypeLink > 0)"
                                                        title="Service to apply" type="button" class="btn btn-md btn-outline-primary"
                                                        id="btGetDetails" [routerLink]="['/single-products2']"
                                                        [queryParams]="{ServiceID:service.ID,ServiceName:service.A1,Price:price,Remark:remark,Type:type,AuthType:authType}">
                                                        {{applyHere}} </button>
                                                    <button *ngIf="userID != '' && service.ServiceTypeLink > 0" title="Service to apply"
                                                        type="button" class="btn btn-md btn-outline-primary"
                                                        [routerLink]="['/ese-family-services']"
                                                        [queryParams]="{sType:service.ServiceTypeLink}"> {{applyHere}} </button>
                                                    <button *ngIf="userID == ''" title="Service to apply" type="button"
                                                        class="btn btn-md btn-outline-primary" (click)="openModal()"
                                                        data-bs-toggle="modal" data-bs-target="#loginRegisterModal"> {{applyHere}}
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- <h1 class="text-start"><strong>{{serviceTypes.A1}}</strong></h1>
                <div class="live-preview serviceList">
                    <table class="table table-striped table-hover mt-5 mb-5">
                        <tbody>
                            <tr *ngFor="let service of serviceList | subService:serviceTypes.ID">
                                <td class="col-6 text-start"><strong>{{service.A1}}</strong></td>
                                <td class="col-3"><div class="arrow"> ➜ </div></td>
                                <td class="col-3">
                                    <button *ngIf="userID != '' && !(service.ServiceTypeLink > 0)" title="Service to apply" type="button" class="btn btn-md btn-outline-primary" id="btGetDetails" [routerLink]="['/single-products2']" [queryParams]="{ServiceID:service.ID,ServiceName:service.A1,Price:price,Remark:remark,Type:type,AuthType:authType}"> {{applyHere}} </button>
                                    <button *ngIf="userID != '' && service.ServiceTypeLink > 0" title="Service to apply" type="button" class="btn btn-md btn-outline-primary" [routerLink]="['/ese-family-services']" [queryParams]="{sType:service.ServiceTypeLink}"> {{applyHere}} </button>
                                    <button *ngIf="userID == ''" title="Service to apply" type="button" class="btn btn-md btn-outline-primary" (click)="openModal()" data-bs-toggle="modal" data-bs-target="#loginRegisterModal"> {{applyHere}} </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
    <div class="col-md-2"></div>
</div>

<app-footer-style-one></app-footer-style-one>